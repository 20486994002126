<footer class="row">
    <div class="col-12">
        <p>Travis Redington | 646-522-3786 | iamredtrowl@gmail.com | Edison, NJ 08837<p>

        <div class="d-block d-sm-none mb-3">
            <div class="d-inline-block mr-3" style="position: relative;"><a href="https://www.linkedin.com/in/travis-redington/"><img src="../../assets/global/linked-in.png" height="30"></a></div>
            
            <a href="https://docs.google.com/document/d/1b6RMX_BL1nkdgjn09RbNa9iPUyXqm7glNaYf_q8w46g/edit?usp=sharing" class="hvr-grow">resume <small style="color: #aaa;">( on google docs )</small></a>
        </div>

        <p><small>&copy; 2022 redtrowl. All rights reserved</small></p>
    </div>
</footer>
