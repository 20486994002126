import { Component, OnInit, AfterContentInit } from '@angular/core';
import { getLocaleDateFormat } from '@angular/common';

@Component({
  selector: 'app-data-vis',
  templateUrl: './data-vis.component.html',
  styleUrls: ['./data-vis.component.scss']
})
export class DataVisComponent implements OnInit, AfterContentInit {

  currentdate = new Date();
  contentLoaded: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.contentLoaded = true;
  }

}
