<div id="main-content" class="row">
    <div class="d-none d-sm-block col-2"></div>

    <div class="col-xs-12 col-sm-8">
        <div class="jumbotron mt-5 mb-5">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-4">
                    <div class="character-card" >
                      <div class="key" (click)="onSwapProfile()" style="z-index: 999;">
                        <img class="hvr-buzz" src="../../assets/home/antennea-01.png" alt="">
                      </div>
                      <div class="text-container">
                        <div class="img-container mb-2">
                          <img *ngIf="swapProfile" src="../../assets/home/me12.jpg" class="img-fluid" alt="" [@fadeInOut] style="position: absolute; z-index: 1;">
                          <img src="../../assets/home/me15.jpg" class="img-fluid" alt="">
                        </div>
                        

                        <span *ngIf="swapProfile">redtrowl <small>[ red-troll ]</small> is a magical creature <small>( at least he thinks so )</small> that looks a little scary <small>( i think he may not be )</small> . his special qualities are his ability and preference to stay in <small >( his happy place of )</small> generic <small>( bad word )</small> reality with no defined meanings and only potentials existing around him. he really only likes looking at cool things and watching them emerge as well as making art from well crafted unwanted things <small>(garbage)</small>.<!--making his clothes from old apholstery<small > ( if you wanted to know )</small>--></span>

                        <span *ngIf="!swapProfile">actually i am just me <small>( travis )</small>, whoever that is. redtrowl may be magical but i guess i am only when i discover the substance of reality. i like buddhism and psychology, <a class="hvr-pulse-shrink" href="#" data-toggle="modal" data-target="#thingsModal" (click)="getThingsData()">making things</a>, being with my <a class="hvr-pulse-shrink" href="#" data-toggle="modal" data-target="#kidsModal">kids</a>, watching something good, listening to music <small>( a lot )</small>, playing music <small>( bass, guitar, and trying to sing )</small>, lots of walking, and eating <small>(somewhat)</small> healthy.</span>
                      </div>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                    <h1 class="display-4">i am <span class="red">red</span>trowl</h1>
                    <p class="lead lighter">[ red-troll ]</p>
                    <hr class="my-4">
                    <div class="row">
                        <div class="col-12">
                            <p class="font-larger">
                              I am a front-end designer/developer with 17+ years of experience. I have worked for small clients as well as many <a class="hvr-pulse-shrink" href="#" data-toggle="modal" data-target="#clientModal" (click)="getClientData()">fortune 500 companies</a> over the years. I have also been a <a class="hvr-pulse-shrink" href="#dataVis">data visualization</a> as well as a flash designer/developer. 
                            </p>

                            <p>I am also a visual artist + bass player ( learning to sing - watch out ) who dabbles in a lot of mediums, check out my stuff <a href="#things">below</a>.</p>

                            <p>Currently looking for a contract, project, or full-time position.</p>

                            <!-- <p>
                              I can offer advice on creating a custom website and social media presence or help you get started with any of the current DIY website platforms ( Wix, Squarespace, etc ). A basic breakdown of what I offer:
                            </p>
                        
                            <ul class="ml-0">
                                <li><span>&#9733;</span> give you a web presence:
                                  <ul class="ml-4">
                                      <li><span>&#9734;</span> website ( custom or DIY )</li>
                                      <li><span>&#9734;</span> social media profile(s)</li>
                                  </ul>
                                </li>
                                <li><span>&#9733;</span> help you with your existing web presence</li>
                                <li><span>&#9733;</span> <a class="hvr-pulse-shrink" href="#branding">branding &amp; marketing</a></li>
                              </ul>

                              <p>I work at a rate of $50 per hour with no hidden fees or anything.</p> -->
                        </div>

                        <!-- <div class="col-3">
                            <img src="../../assets/home/redtrowl_standing-no-shadow2.png" class="img-fluid" alt="">
                        </div> -->
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
    <div class="d-none d-sm-block col-2"></div>
</div>

<!-- shop Modal -->
<div class="modal fade" id="shopModal" tabindex="-1" role="dialog" aria-labelledby="shopModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 45%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="shopModalLabel">the shop</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        <img src="../../assets/home/shop3.jpg" alt="" class="img-fluid">
        </div>
      </div>
    </div>
</div>


<!-- client Modal -->
<div class="modal fade" id="clientModal" tabindex="-1" role="dialog" aria-labelledby="clientModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="max-width: 80%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="clientModalLabel">Contracts &amp; Full-time</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="clients">
        <div class="spinner-container" *ngIf="!clientContentLoaded">
          <img src="../../assets/global/spinner1.gif" alt="">
        </div>

        <div *ngIf="clientContentLoaded">
          <div *ngFor="let client of clients" class="client-container mr-4">
            <app-client [logo]="client.logo" [title]="client.title" [text]="client.text"></app-client>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- things Modal -->
<div class="modal fade" id="thingsModal" tabindex="-1" role="dialog" aria-labelledby="thingsModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="max-width: 80%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="thingsModalLabel">Some of the Things I Make</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-container">
          <div class="spinner-container" *ngIf="!thingsContentLoaded">
            <img src="../../assets/global/spinner1.gif" alt="">
          </div>

          <div *ngIf="thingsContentLoaded"><
            <div *ngFor="let thing of things" class="thing card">
              <img [src]="thing.src" class="card-img-top img-fluid" alt="thing.title">
              <div class="card-body">
                <h5 class="card-title">{{ thing.title }}</h5>
                <p class="card-text">{{ thing.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- kids Modal -->
<div class="modal fade" id="kidsModal" tabindex="-1" role="dialog" aria-labelledby="kidsModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="max-width: 50%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="kidsModalLabel">my kids</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../../assets/home/kids1.jpg" alt="" class="img-fluid">
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


