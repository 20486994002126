import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import {
	trigger,
	state,
	style,
	animate,
	transition
} from '@angular/animations';

/**svgs**/
// import { friends02_color } from '../../assets/svgs/friends-02_color';
// import { friends03_color } from '../../assets/svgs/friends-03_color';
// import { friends04_color } from '../../assets/svgs/friends-04_color';
// import { friends05_color } from '../../assets/svgs/friends-05_color';
// import { filigree15 } from '../../assets/svgs/filigree15';
// import { filigree16 } from '../../assets/svgs/filigree16';
// import { filigree17 } from '../../assets/svgs/filigree17';
// import { filigree18 } from '../../assets/svgs/filigree18';
// import { filigree19 } from '../../assets/svgs/filigree19';
// import { friends1b } from '../../assets/svgs/friends1b';

declare var $;

@Component({
  selector: 'app-random',
  templateUrl: './random.component.html',
  styleUrls: ['./random.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ 'opacity': '1' })),
      state('out', style({ 'opacity': '0' })),
      transition('* <=> *', [
        animate(1500)
      ])
    ])
  ]
})
export class RandomComponent implements OnInit, AfterContentInit {
  @Input('generate') generate: boolean = null;
  @Input('size') size: any = {
    'height': '350px',
    'width': '100%'
  };
  @Input() isDigital: boolean = false;

  contentLoaded: boolean = false;
  isGreen: string = 'true';
  choice: number = 2;
  state: string = 'in';
  counter:number = 0;
  enableAnimation: boolean = false;
  imageSource: string = '';
  imgSrc1: string = '';
  imgSrc2: string = '';

  colors_1: string[] = [
    '#000000', '#FFFFFF', '#481818', '#301800', '#000018', '#180000', '#783018', '#FFFFF0', '#786018', '#F0F0F0', '#C0A8C0', '#C04830', '#907830', '#FFF0D8', '#603000', '#A89048', '#FFA8A8', '#183030', '#C0A860', '#F06048', '#A8A890', '#784800', '#909078', '#D8C078', '#001818', '#486048', '#F0FFF0', '#FF9060', '#FF7848', '#D84860', '#489090', '#007818', '#00A848', '#187848', '#F0FFFF', '#F078A8', '#30C0A8', '#78F0C0', '#A8F0D8', '#00A878', '#60D8A8', '#30D890', '#48D8C0', '#18D8C0', '#F090D8', '#903030', '#181800', '#D84830', '#F0D890'	, '#906018', '#FFFFD8', '#601818', '#301818', '#483018', '#481800', '#D8D8C0', '#603018', '#C03030', '#FFF0F0', '#F0F0D8', '#903018', '#A89078', '#D8C0C0', '#D86048', '#C0C0A8', '#906048', '#906030','#F0F0C0', '#786030', '#484830', '#C0C0C0', '#A87830', '#784818', '#A84830',	'#D8D890',	'#F0D8A8',	'#786048', '#D8A860', '#300000', '#C09048', '#181818', '#C0C090', '#781818', '#787860', '#A83018', '#FFA878', '#C06048', '#601800', '#F07848', '#783000', '#D8C0A8', '#483000', '#D8C090', '#483030', '#FFF0A8', '#604818', '#C0A890', '#A86030', '#D8F0D8', '#904818'	
  ];
  colors_2: string[] = ["#000000","#fffffc","#beb7a4","#ff7f11","#ff3f00"];
  colors_3: string[] = ["#F8E2AF","#BDB8B0","#010203","#484A53","#E3E5DB","#3E312C","#ECB668","#CCA973","#101116","#4E504F"];
  colors_4: string[] = ['#9eaebd', '#615f77', '#e38792', '#febece', '#876073', '#404145', '#e0bfce', '#404751', '#ffd8f0', '#cfb2c8', '#ff969e', '#eeb9d7', '#43484e', '#8e9fb1', '#81687d', '#ffe3f5', '#454a50', '#b2c0cd', '#757a92', '#ffcdde']

  svgs: any[] = [
    // {
    //   name: 'friends02_color',
    //   code: friends02_color
    // },
    // {
    //   name: 'friends03_color',
    //   code: friends03_color
    // },
    // {
    //   name: 'friends04_color',
    //   code: friends04_color
    // },
    // {
    //   name: 'friends05_color',
    //   code: friends05_color
    // },
    // {
    //   name: 'friends1b',
    //   code: friends1b
    // },
    // {
    //   name: 'filigree15',
    //   code: filigree15
    // },
    // {
    //   name: 'filigree16',
    //   code: filigree16
    // },
    // {
    //   name: 'filigree17',
    //   code: filigree17
    // },
    // {
    //   name: 'filigree18',
    //   code: filigree18
    // },
    // {
    //   name: 'filigree19',
    //   code: filigree19
    // }
  ];
  divs: any[] = [];
  
  // for animation
  currentLoader: string = '1';
  animationTimeout: any;
  timer: number = 10000;
  animationTimer: number = 3000;

  // for info popover
  randomFacts: string[] = [
    'he put red food coloring in his eyes as well.',
    'he wrote a book about flash that i bought as well.',
    'he also burned his art, which also inspired mt to burn my art (sometimes).',
    'he also tought about components in flash waaay before i got into them in angular.',
    'he also made animated backdrop art for lollapalooza in like 2005.'
  ];
  infoMessage: string[] = [
    'i have to give credit for the inspiration of this random image/color generator i built to make these images to an early flash teacher i had when i first started in 2004.',
    'while i did build this random image/color component from scratch and formatted all the assets as svgs etc, the original concept of a random animation/image generator i got from a a flash teacher i had in 2004.',
    'this sucker (random image/color generator) was hard to build! lots of complex (for me) thought and image formatting. however the original concpet i credit to an early flash teacher i had at sva. i started as a flash dev you know.',
    'this is my (random image/color generator) build and take on it but it was inspired by a flash teacher i had when i started this career as a flash dev. i still love flash and use it sometimes for animations (even though apple killed it in 2009).',
    'i love this idea (uh random image/color generator) and use it sometimes in my own work and i always try to give credit where it is due so this concept goes back to 2004 with a teacher i had for flash dev @ sva.' 
  ];
  infoContent: string;

  constructor() { }

  ngOnInit(): void {
    let self = this;

    if(this.generate) { this.generateRandom(); return; } else {
      // add this back in if want to add animation back in
      // this.initDisplayRandomJpg();
      let path = this.isDigital ? '../../assets/global/randomComp/digital' : '../../assets/global/randomComp/random';

      this.imageSource = path + this.random(this.isDigital ? 62: 55) + '.jpg';
      this.infoContent = 'test message' + this.random(5);

      $(function () {
        $('.info').popover({
          container: 'body',
          content: function () {
            return self.infoMessage[Math.floor(Math.random() * 5)] + ' ' + self.randomFacts[Math.floor(Math.random() * 5)]
          }
        })
      });
    }
  }

  ngAfterContentInit(): void {
    this.contentLoaded = true;
  }

  public generateRandom(): void {
    $('.random-container').css({
      // 'background': 'url(../../assets/global/bgImages/random/photoBg4.jpg) no-repeat center center fixed',
      // 'backgroundSize': 'cover'
      'background-image': 'none'
    });

    for(let i = 0; i < this.svgs.length; i++) {
      let div = document.createElement('div');

      $(div).html(this.svgs[i].code);

      this.divs.push(div);
    }

    this.applyColorPalate();
  }

  public randomize(): void {
    for(let i = 0; i < this.divs.length; i++) {
      let div = this.divs[i];

      // set the styles
      // div = this.setStyles(div);
      div = this.setSVGStyles(div);

      // append the div
      $('.canvas').append(div);
    }
  }

  private random(num: number): number {
    num = Math.floor(Math.random() * num) + 1;

    return num;
  }

  private setSVGStyles(div: any): any {
    let svg =  $('svg', div);

     svg.find('path').each((j)=>{
        let path = $('path', svg).get(j);

        let scale = Math.random();
        $(path).css('transform', 'scale(' + scale + ')');

        // $('svg', div).css({'position': 'absolute', 'left': _left + 'px'  , 'top': _top + 'px' });

        // rotation
        $(path).css( 'transform', 'rotate('+ (Math.floor(Math.random() * 360)) + 1 +'deg)'); 
    
        // opacity
        $(path).css( 'opacity', Math.random());

        $(path).css( 'filter', 'blur(1.5rem)');
      })


    
    // scale    
    // let scale = Math.random();
    // $(div).css('transform', 'scale(' + scale + ')');

    // position
    // let _left = (Math.floor(Math.random() * $('.random-container').width()) + 1) - 100;
    // let _top = (Math.floor(Math.random() * $('.random-container').height()) + 1) - 100;

    // $('svg', div).css({'position': 'absolute', 'left': _left + 'px'  , 'top': _top + 'px' });

    // // rotation
    // $('svg', div).css( 'transform', 'rotate('+ (Math.floor(Math.random() * 360)) + 1 +'deg)'); 

    // // opacity
    // $('svg', div).css( 'opacity', Math.random());

    return div;
  }

  private setStyles(div: any): any {
    // scale, opacity, rotation, position(x/y)

    // for testing
    // $(div).css({'backgroundColor': '#ccc', 'border': '1px solid #666'});

    // scale
    let scale = Math.random();
    $(div).css('transform', 'scale(' + scale + ')');

    // position
    let _left = (Math.floor(Math.random() * $('.random-container').width()) + 1) - 100;
    let _top = (Math.floor(Math.random() * $('.random-container').height()) + 1) - 100;

    $('svg', div).css({'position': 'absolute', 'left': _left + 'px'  , 'top': _top + 'px' });

    // rotation
    $('svg', div).css( 'transform', 'rotate('+ (Math.floor(Math.random() * 360)) + 1 +'deg)'); 

    // opacity
    $('svg', div).css( 'opacity', Math.random());

    return div;
  }

  private applyColorPalate(): void {
    for(let i: number = 0; i < this.divs.length; i++) {
      let svg = $('svg', this.divs[i]);

      $(svg).find('path').each((j)=>{
        let path = $('path', svg).get(j);

        $(path).attr('fill', this.colors_4[Math.floor(Math.random() * this.colors_4.length )]);
      })
    }

    this.randomize();
  }




  private initDisplayRandomJpg() {
    clearTimeout(this.animationTimeout);
    this.enableAnimation = true;
    this.counter = 0;
    this.toggleState();
  }

  private toggleImg() {
    let random = this.random(35);

    if (this.choice === 1) { 
      this.choice = 2;
    } else {
      this.choice = 1;
    }

    // console.log('random = ', random);

    this.imageSource = '../../assets/global/randomComp/random' + random + '.jpg';
  }

  public onDone($event) {
    if (this.enableAnimation) {
      if (this.counter === 1) {
        this.toggleImg();
      }
      
      this.toggleState();
    }
  }

  private toggleState() {
    // console.log('toggleState ', this.counter);

    if(this.counter > 1) {
      console.log('switchimage');
      $('.random-container').css('background-image', 'url(' + this.imageSource + ')')
      this.animationTimeout = setTimeout(()=>{
        $('.random-container img').click();
      }, this.animationTimer);
    }

    if (this.counter < 2) {
      this.state = this.state === 'in' ? 'out' : 'in';
      this.counter++;
    }
  }

/**
Extract Colors from Image
Ready for 100% Brainpower? Kyäni's FX Triangle of Wellness products are a potent combination of some of the earth's most powerful ingredients. These provide the human body with a solid foundation of restorative and nutritive elements that work together to support the body's systems and create an individual in better shape. BUY NOW!
Color	HEX Code	RGB Code	Percentage
'#A8D8D8'	RGB(168, 216, 216)	0.49265
'#F0C0A8'	RGB(240, 192, 168)	0.06000
'#C0D8F0'	RGB(192, 216, 240)	0.05419
'#000000'	RGB(0, 0, 0)	0.04949
'#181818'	RGB(24, 24, 24)	0.03607
'#D8A878'	RGB(216, 168, 120)	0.03128
'#303030'	RGB(48, 48, 48)	0.02701
'#607878'	RGB(96, 120, 120)	0.01957
'#789090'	RGB(120, 144, 144)	0.01897
'#486060'	RGB(72, 96, 96)	0.01803
'#F0A800'	RGB(240, 168, 0)	0.01530
'#48C000'	RGB(72, 192, 0)	0.01402
'#60D800'	RGB(96, 216, 0)	0.00957
'#F06000'	RGB(240, 96, 0)	0.00752
'#903000'	RGB(144, 48, 0)	0.00726
'#F0F000'	RGB(240, 240, 0)	0.00718
'#603030'	RGB(96, 48, 48)	0.00530
'#78C0F0'	RGB(120, 192, 240)	0.00487
'#D87800'	RGB(216, 120, 0)	0.00479
'#309000'	RGB(48, 144, 0)	0.00462
'#4830C0'	RGB(72, 48, 192)	0.00462
'#600060'	RGB(96, 0, 96)	0.00453
'#4878F0'	RGB(72, 120, 240)	0.00453
'#7848D8'	RGB(120, 72, 216)	0.00444
'#F07818'	RGB(240, 120, 24)	0.00436
'#3060F0'	RGB(48, 96, 240)	0.00436
'#A84800'	RGB(168, 72, 0)	0.00385
'#6090F0'	RGB(96, 144, 240)	0.00376
'#483018'	RGB(72, 48, 24)	0.00368
'#A87860'	RGB(168, 120, 96)	0.00359
'#6030D8'	RGB(96, 48, 216)	0.00359
'#784830'	RGB(120, 72, 48)	0.00265
'#A86018'	RGB(168, 96, 24)	0.00265
'#C09060'	RGB(192, 144, 96)	0.00256
'#60D830'	RGB(96, 216, 48)	0.00248
'#C0C000'	RGB(192, 192, 0)	0.00214
'#F07848'	RGB(240, 120, 72)	0.00205
'#F0C018'	RGB(240, 192, 24)	0.00188
'#904848'	RGB(144, 72, 72)	0.00179
'#A89078'	RGB(168, 144, 120)	0.00179
'#A8A848'	RGB(168, 168, 72)	0.00171
'#000018'	RGB(0, 0, 24)	0.00154
'#6090C0'	RGB(96, 144, 192)	0.00145



'#C0D860'	


'#489060'	


'#609000'	


'#786018'

'#60A8D8'	RGB(96, 168, 216)	0.00128
'#483078'	RGB(72, 48, 120)	0.00120
'#F06030'	RGB(240, 96, 48)	0.00111
'#C04830'	RGB(192, 72, 48)	0.00111
'#901818'	RGB(144, 24, 24)	0.00111
'#D84848'	RGB(216, 72, 72)	0.00103
'#3048C0'	RGB(48, 72, 192)	0.00085
'#78C060'	RGB(120, 192, 96)	0.00068
'#F0C048'	RGB(240, 192, 72)	0.00068
'#181890'	RGB(24, 24, 144)	0.00068
'#4860D8'	RGB(72, 96, 216)	0.00060
'#C03048'	RGB(192, 48, 72)	0.00051
'#90C090'	RGB(144, 192, 144)	0.00043
'#307848'	RGB(48, 120, 72)	0.00043
'#C0A8F0'	RGB(192, 168, 240)	0.00043
'#303090'	RGB(48, 48, 144)	0.00034
'#90C048'	RGB(144, 192, 72)	0.00026
'#780000'	RGB(120, 0, 0)	0.00026
'#4848A8'	RGB(72, 72, 168)	0.00026
'#904878'	RGB(144, 72, 120)	0.00026
'#90D8A8'	RGB(144, 216, 168)	0.00026
'#9078F0'	RGB(144, 120, 240)	0.00026
'#90D878'	RGB(144, 216, 120)	0.00017
'#789060'	RGB(120, 144, 96)	0.00017
'#306018'	RGB(48, 96, 24)	0.00017
'#90D8FF'	RGB(144, 216, 255)	0.00009
'#60A878'	RGB(96, 168, 120)	0.00009
'#90A818'	RGB(144, 168, 24)	0.00009
'#60A848'	RGB(96, 168, 72)	0.00009
'#C0A818'	
'#A8C0C0'	
'#F0C078'	
'#D8A860'	
'#C0C0A8'	
'#F04800'	
'#F0D890'	
'#481818'	
'#F0A878'	
'#C00000'	
'#181830'	
'#90A8A8'	
'#301818'	
'#6048D8'	
'#C0A890'	
'#F0C000'	
'#D8A8A8'	
'#C0F0F0'	
'#F0A890'	
'#C0A878'	
'#4890F0'	
'#D8C078'
 */
}
