<div class="row spinner-container" *ngIf="!contentLoaded">
    <div class="col-12">
      <img src="../../assets/global/spinner1.gif" alt="">
    </div>
</div>

<div class="row dataVis-container" id="dataVis" *ngIf="contentLoaded">
    <div class="col-12 mt-4 mb-4">
        <h2 class="mb-4">data visualizations <a href="#header" class="ml-5 hvr-float"><img src="../../assets/global/top.png" alt=""></a></h2>

        <p>I will be adding to this section in the coming weeks ( unless i get a job - in that case it may be months :)</p>
            
        <p>the current date is {{currentdate}}</p>

        <p>the date of this statement is 08/19/20</p>

        <small>( you see if i drop the ball on this or not! )</small>
    </div>
</div>
