import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScratchComponent } from './scratch/scratch.component';

const routes: Routes = [
  { path: 'scratch', component: ScratchComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
