<!-- <div class="row spinner-container" *ngIf="!contentLoaded">
    <div class="col-12">
      <img src="../../assets/global/spinner1.gif" alt="">
    </div>
</div> -->

<!-- <div class="row stuff-container" id="things" *ngIf="things && contentLoaded"> -->
<div class="row stuff-container" id="things">
    <div class="col-12">
        <h2 class="mt-4 mb-4">hey, it's my stuff! <a href="#header" class="ml-5 hvr-float"><img src="../../assets/global/top.png" alt=""></a></h2>

        <p>here is some more of my stuff or things if you will. things i make. i make things a lot. it's one of the reasons i find for living.</p>

        <p>i also make music, <a href="https://www.youtube.com/channel/UCwhC2xERWyrzoL1y_8BwvPg" class="hvr-pop" target="_blank">videos</a>, and  lots of writing exploratory explanations of my existence - maybe someday I'll put that out there.</p>

        <div class="accordion mb-4 mt-4" id="stuffAccordion">
            <div class="card">
                <div class="card-header accordion-header">
                    <h2 class="mb-0">
                        <button class="btn btn-block text-left hvr-wobble-horizontal" type="button" data-toggle="collapse" data-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero" (click)="getData()">
                            digital meanderings
                        </button>
                    </h2>
                </div>

                <div id="collapseZero" class="collapse" aria-labelledby="headingOne" data-parent="#stuffAccordion">
                    <div class="card-body" data-toggle="collapse" data-target="#collapseZero">
                        <div class="things-container">
                            <div class="spinner-container mb-4" *ngIf="!contentLoaded" style="margin: 0 auto; width: 47px; height: 47px;">
                                <img src="../../assets/global/spinner1.gif" alt="">
                            </div>
                            
                            <div *ngIf="contentLoaded">
                                <div class="card full" [ngClass]="{'isClicked': thing.clickState}" *ngFor="let thing of things.digital; let i = index;" (click)="onClick(i, 'digital')">

                                    <img [src]="thing.src" class="card-img-top img-fluid" alt="thing.title">

                                    <div class="card-body">
                                        <h5 class="card-title">{{ thing.title }}</h5>

                                        <p class="card-text">{{ thing.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header accordion-header">
                    <h2 class="mb-0">
                        <button class="btn btn-block text-left hvr-wobble-horizontal" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" (click)="getData()">
                            sculpture objects
                        </button>
                    </h2>
                </div>

                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#stuffAccordion">
                    <div class="card-body" data-toggle="collapse" data-target="#collapseOne">
                        <div class="things-container">
                            <div class="spinner-container mb-4" *ngIf="!contentLoaded" style="margin: 0 auto; width: 47px; height: 47px;">
                                <img src="../../assets/global/spinner1.gif" alt="">
                            </div>
                            
                            <div *ngIf="contentLoaded">
                                <div class="card hvr-bounce-in" [ngClass]="{'isClicked': thing.clickState}" *ngFor="let thing of things.sculpture; let i = index;" (click)="onClick(i, 'sculpture')">

                                    <img [src]="thing.src" class="card-img-top img-fluid" alt="thing.title">

                                    <hr>

                                    <div class="card-body">
                                        <h5 class="card-title">{{ thing.title }}</h5>

                                        <p class="card-text">{{ thing.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header accordion-header">
                    <h2 class="mb-0">
                        <button class="btn btn-block text-left hvr-wobble-horizontal" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" (click)="getData()">
                            drawing/sketching stuff
                        </button>
                    </h2>
                </div>

                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#stuffAccordion">
                    <div class="card-body" data-toggle="collapse" data-target="#collapseTwo">
                        <div class="things-container">
                            <div class="spinner-container mb-4" *ngIf="!contentLoaded" style="margin: 0 auto; width: 47px; height: 47px;">
                                <img src="../../assets/global/spinner1.gif" alt="">
                            </div>

                            <div *ngIf="contentLoaded">
                                <div class="card hvr-bounce-in" [ngClass]="{'isClicked': thing.clickState}" *ngFor="let thing of things.draw; let i = index;" (click)="onClick(i, 'draw')">

                                    <img [src]="thing.src" class="card-img-top img-fluid" alt="thing.title">

                                    <hr>

                                    <div class="card-body">
                                        <h5 class="card-title">{{ thing.title }}</h5>

                                        <p class="card-text">{{ thing.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <p>holy makerel i didn't think you'd make it through that! sorry it's a lot:) you deserve something you like.</p>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header accordion-header">
                    <h2 class="mb-0">
                        <button class="btn btn-block text-left hvr-wobble-horizontal" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" (click)="getData()">
                            painting things
                        </button>
                    </h2>
                </div>

                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#stuffAccordion">
                    <div class="card-body" data-toggle="collapse" data-target="#collapseThree">
                        <div class="things-container">
                            <div class="spinner-container mb-4" *ngIf="!contentLoaded" style="margin: 0 auto; width: 47px; height: 47px;">
                                <img src="../../assets/global/spinner1.gif" alt="">
                            </div>

                            <div *ngIf="contentLoaded">
                                <div class="card hvr-bounce-in" [ngClass]="{'isClicked': thing.clickState}" *ngFor="let thing of things.paint; let i = index;" (click)="onClick(i, 'paint')">

                                    <img [src]="thing.src" class="card-img-top img-fluid" alt="thing.title">

                                    <hr>

                                    <div class="card-body">
                                        <h5 class="card-title">{{ thing.title }}</h5>

                                        <p class="card-text">{{ thing.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header accordion-header">
                    <h2 class="mb-0">
                        <button class="btn btn-block text-left hvr-wobble-horizontal" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseThree" (click)="getData()">
                            creature peeps
                        </button>
                    </h2>
                </div>

                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#stuffAccordion">
                    <div class="card-body" data-toggle="collapse" data-target="#collapseFour">
                        <div class="things-container">
                            <div class="spinner-container mb-4" *ngIf="!contentLoaded" style="margin: 0 auto; width: 47px; height: 47px;">
                                <img src="../../assets/global/spinner1.gif" alt="">
                            </div>

                            <div *ngIf="contentLoaded">
                                <div class="card hvr-bounce-in" [ngClass]="{'isClicked': thing.clickState}" *ngFor="let thing of things.creature; let i = index;" (click)="onClick(i, 'paint')">

                                    <img [src]="thing.src" class="card-img-top img-fluid" alt="thing.title">

                                    <hr>

                                    <div class="card-body">
                                        <h5 class="card-title">{{ thing.title }}</h5>

                                        <p class="card-text">{{ thing.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header accordion-header">
                    <h2 class="mb-0">
                        <button class="btn btn-block text-left hvr-wobble-horizontal" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" (click)="getData()">
                            building useful items
                        </button>
                    </h2>
                </div>

                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#stuffAccordion">
                    <div class="card-body" data-toggle="collapse" data-target="#collapseFive">
                        <div class="things-container">
                            <div class="spinner-container mb-4" *ngIf="!contentLoaded" style="margin: 0 auto; width: 47px; height: 47px;">
                                <img src="../../assets/global/spinner1.gif" alt="">
                            </div>

                            <div *ngIf="contentLoaded">
                                <div class="card hvr-bounce-in" [ngClass]="{'isClicked': thing.clickState}" *ngFor="let thing of things.build; let i = index;" (click)="onClick(i, 'build')">

                                    <img [src]="thing.src" class="card-img-top img-fluid" alt="thing.title">

                                    <hr>

                                    <div class="card-body">
                                        <h5 class="card-title">{{ thing.title }}</h5>

                                        <p class="card-text">{{ thing.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
