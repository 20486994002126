<div class="row branding-container" id="branding">
    <div class="col-12 mb-4">
        <h2 class="mt-4 mb-4">branding <a href="#header" class="ml-5 hvr-float"><img src="../../assets/global/top.png" alt=""></a></h2>

        <p>some past graphic design work</p>

        <div class="accordion mb-4 mt-4" id="brandingAccordion">
            <div class="card">
                <div class="card-header accordion-header">
                    <h2 class="mb-0">
                        <button class="btn btn-block text-left hvr-wobble-horizontal" type="button" data-toggle="collapse" data-target="#brandingOne" aria-expanded="true" aria-controls="brandingOne" (click)="getData()">
                            logos
                        </button>
                    </h2>
                </div>

                <div id="brandingOne" class="collapse" aria-labelledby="headingOne" data-parent="#brandingAccordion">
                    <div class="card-body" data-toggle="collapse" data-target="#brandingOne">
                        <div class="branding-card-container">
                            <div class="spinner-container" *ngIf="!contentLoaded">
                                <img src="../../assets/global/spinner1.gif" alt="">
                            </div>

                            <div *ngIf="contentLoaded">
                                <div class="card hvr-bounce-in" [ngClass]="{'isClicked': logo.clickState}" *ngFor="let logo of branding.logos; let i = index;" (click)="onClick(i, 'logos')">
                                    <img [src]="logo.src" class="card-img-top img-fluid" alt="logo.title">
                                    <hr>
                                    <div class="card-body">
                                        <h5 class="card-title">{{ logo.title }}</h5>
                                        <p class="card-text">{{ logo.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header accordion-header">
                    <h2 class="mb-0">
                        <button class="btn btn-block text-left hvr-wobble-horizontal" type="button" data-toggle="collapse" data-target="#brandingTwo" aria-expanded="true" aria-controls="brandingTwo" (click)="getData()">
                            business cards
                        </button>
                    </h2>
                </div>

                <div id="brandingTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#brandingAccordion">
                    <div class="card-body" data-toggle="collapse" data-target="#brandingTwo">
                        <div class="branding-card-container">
                            <div class="spinner-container" *ngIf="!contentLoaded">
                                <img src="../../assets/global/spinner1.gif" alt="">
                            </div>

                            <div *ngIf="contentLoaded">
                                <div class="card hvr-bounce-in" [ngClass]="{'isClicked': businessCard.clickState}" *ngFor="let businessCard of branding.businessCards; let i = index;" (click)="onClick(i, 'businessCards')">
                                    <img [src]="businessCard.src" class="card-img-top img-fluid" alt="businessCard.title">
                                    <hr>
                                    <div class="card-body">
                                        <h5 class="card-title">{{ businessCard.title }}</h5>
                                        <p class="card-text">{{ businessCard.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
