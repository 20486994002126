import { Component, OnInit, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentInit {
  initState: boolean = true;
  animateState: boolean = false;
  contentLoaded: boolean = false;

  constructor() { }

  ngAfterContentInit(): void {
    this.contentLoaded = true;
  }

  ngOnInit(): void {
    setTimeout(()=>{ this.animateState = true; }, 500);
    setTimeout(()=>{ this.initState = false; this.animateState = false; }, 1000);
  }
  
}
