<div class="container-fluid">
  <app-header></app-header>
  
  <app-home></app-home>

  <app-random [generate]="false" [isDigital]="true"></app-random>

  <app-branding></app-branding>

  <app-random [generate]="false"></app-random>

  <app-data-vis></app-data-vis>

  <app-random [generate]="false" [isDigital]="true"></app-random>

  <app-stuff></app-stuff>

  <!-- <app-scratch></app-scratch> -->

  <app-footer></app-footer>
</div>