import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ApiService } from '../services/api.service';

// declare var $;

@Component({
  selector: 'app-stuff',
  templateUrl: './stuff.component.html',
  styleUrls: ['./stuff.component.scss']
})

export class StuffComponent implements OnInit, AfterContentInit {
  clickState;
  things: any;
  contentLoaded: boolean = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    // this.getThingsData();
  }

  ngAfterContentInit(): void {
    // this.contentLoaded = true;
  }

  public onClick(i: number, cat: string): void {
    // may add back later

    // $('.card.isClicked').removeClass('isClicked');

    // let clickState = this.things[cat][i]['clickState'];

    // this.things[cat][i]['clickState'] = !clickState;

    // console.log('this is ', this.things[cat][i]['clickState'], clickState);
  }

  public getData(): void {
    this.getThingsData();
  }

  private getThingsData(): void {
    if(!this.contentLoaded) {
      this.api.getData('assets/data/things.data.json').subscribe(data => {
        console.log('this is loaded </div>');
        this.things = data.body.things;
        this.contentLoaded = true;
      });
    }
  }
}
