<div class="row spinner-container" *ngIf="!contentLoaded">
    <div class="col-12">
      <img src="../../assets/global/spinner1.gif" alt="">
    </div>
</div>

<div class="row random-container" *ngIf="contentLoaded">
    <!-- <div class="col-12 img-loader loader1" style="padding: 0;">
        <div class="canvas" #randomCanvas></div>
    </div> -->

    <div class="col-12" style="padding: 0;">
        <img [@fade]="state" (@fade.done)="onDone($event)" [src]="imageSource" />
    </div>

    <a tabindex="0" class="info hvr-pulse" role="button" data-toggle="popover" data-trigger="focus" title="i: inspirational credit"><img src="../../assets/global/info.png" alt=""></a>

</div>
