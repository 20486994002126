<div class="row spinner-container" *ngIf="!contentLoaded">
    <div class="col-12">
        <img src="../../assets/global/spinner1.gif" alt="">
    </div>
</div>

<header class="row header-bg" id="header" *ngIf="contentLoaded">
    <div id="logo" class="col-12">
        <img src="../../assets/global/logo_img.png" [ngClass]="{'isInit': initState, 'isActive': animateState}" class="img-fluid hvr-bob init-bounce-in" alt="redtrowl img">
        <img src="../../assets/global/logo_text.png" [ngClass]="{'isInit': initState, 'isActive': animateState}" class="img-fluid hvr-bob init-slide-in-right" alt="redtrowl text">
    </div>
</header>

<div class="row nav-container" *ngIf="contentLoaded">
    <nav class="col-xs-12 col-sm-9 header-nav">
        <ul>
            <li><a href="#branding" class="hvr-pop">branding</a></li>
            <li><a href="#dataVis" class="hvr-pop">data vis</a></li>
            <li><a href="#things" class="hvr-pop">things and or stuff</a></li>
        </ul>
    </nav>
    <div class="d-none d-sm-block col-sm-3">
        <div class="float-right d-none d-none d-sm-block">
            <div class="d-inline-block mr-3" style="position: relative;"><a href="https://www.linkedin.com/in/travis-redington/" style="position: absolute; top: -20px; left: -25px;" class="hvr-bounce-in"><img src="../../assets/global/linked-in.png" height="30"></a></div>
            
            <a href="https://docs.google.com/document/d/1b6RMX_BL1nkdgjn09RbNa9iPUyXqm7glNaYf_q8w46g/edit?usp=sharing" class="hvr-grow">resume <small style="color: #aaa;">( on google docs )</small></a>
        </div>
    </div>
</div>
