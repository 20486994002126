import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterContentInit {
  contentLoaded: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.contentLoaded = true;
  }
}
