<div class="row scratch-container">
    <div class="col-12">
        <!-- <app-random [generate]="true"></app-random> -->

        <hr>

        <h3>prototype for table for J&amp;J</h3>

        <table class="table">
            <thead>
              <tr>
                <th class="column-1" scope="col">Product Name</th>
                <th class="column-1" scope="col">2020 List</th>
                <th class="column-1" scope="col">Current</th>
                <th class="column-1" scope="col">Disc %</th>
              </tr>
            </thead>
            <tbody>
            <!--level 1-->
              <tr class="accordion-toggle" data-toggle="collapse" data-target="#ps_collapse1" aria-expanded="false" aria-controls="ps_collapse1">
                <th class="column-1" scope="row">Plates &amp; Screws</th>
                <td class="column-1">$100</td>
                <td class="column-1">$5000</td>
                <td class="column-1">25%</td>
              </tr>
              <!--level 2-->
              <tr class="accordion-container">
                <td colspan="4">
                    <div class="collapse multi-collapse" id="ps_collapse1">
                        <table class="accordion" style="margin-right: 0;">
                            <tbody>
                                <tr>
                                    <th class="level-2 column-1" scope="row">Plates &amp; Screws - Protect</th>
                                    <td class="column-1">$100</td>
                                    <td class="column-1">$5000</td>
                                    <td class="column-1">25%</td>
                                </tr>
                                <tr>
                                    <th class="level-2 column-1" scope="row">Plates &amp; Screws - Sort of Protected</th>
                                    <td class="column-1">$100</td>
                                    <td class="column-1">$5000</td>
                                    <td class="column-1">25%</td>
                                </tr>
                                <tr>
                                    <th class="level-2 column-1" scope="row">Plates &amp; Screws - Kind of Protected</th>
                                    <td class="column-1">$100</td>
                                    <td class="column-1">$5000</td>
                                    <td class="column-1">25%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
              </tr>
              <!--level 1-->
              <tr class="accordion-toggle" data-toggle="collapse" data-target="#ps_collapse2" aria-expanded="false" aria-controls="ps_collapse2">
                <th class="column-1" scope="row">IM Nails</th>
                <td class="column-1">$150</td>
                <td class="column-1">$6000</td>
                <td class="column-1">35%</td>
              </tr>
             <!--level 2-->
             <tr class="accordion-container">
                <td colspan="4">
                    <div class="collapse multi-collapse" id="ps_collapse2">
                        <table class="accordion" style="margin-right: 0;">
                            <tbody>
                                <tr>
                                    <th class="level-2 column-1" scope="row">IM Nails - Protect</th>
                                    <td class="column-1">$150</td>
                                    <td class="column-1">$6000</td>
                                    <td class="column-1">35%</td>
                                </tr>
                                <tr>
                                    <th class="level-2 column-1" scope="row">IM Nails - Protect</th>
                                    <td class="column-1">$150</td>
                                    <td class="column-1">$6000</td>
                                    <td class="column-1">35%</td>
                                </tr>
                                <tr>
                                    <th class="level-2 column-1" scope="row">IM Nails - Protect</th>
                                    <td class="column-1">$150</td>
                                    <td class="column-1">$6000</td>
                                    <td class="column-1">35%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
              </tr>
              <!--level 1-->
              <tr class="accordion-toggle" data-toggle="collapse" data-target="#ps_collapse3" aria-expanded="false" aria-controls="ps_collapse3">
                <th scope="row">Cannulated Screws</th>
                <td class="column-1">$200</td>
                <td class="column-1">$7000</td>
                <td class="column-1">50%</td>
              </tr>
              <!--level 2-->
                <tr class="accordion-container">
                    <td colspan="4">
                        <div class="collapse multi-collapse" id="ps_collapse3">
                            <table class="accordion" style="margin-right: 0;">
                                <tbody>
                                    <tr>
                                        <th class="level-2 column-1" scope="row">Cannulated Screws - Sort of Protected</th>
                                        <td class="column-1">$200</td>
                                        <td class="column-1">$7000</td>
                                        <td class="column-1">50%</td>
                                    </tr>
                                    <tr>
                                        <th class="level-2 column-1" scope="row">Cannulated Screws - Sort of Protected</th>
                                        <td class="column-1">$200</td>
                                        <td class="column-1">$7000</td>
                                        <td class="column-1">50%</td>
                                    </tr>
                                    <tr>
                                        <th class="level-2 column-1" scope="row">Cannulated Screws - Sort of Protected</th>
                                        <td class="column-1">$200</td>
                                        <td class="column-1">$7000</td>
                                        <td class="column-1">50%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
              </tr>
            </tbody>
          </table>
    </div>
</div>
		