import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ApiService } from '../services/api.service';

declare var $;

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit, AfterContentInit {

  branding: any;
  contentLoaded: boolean = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    // this.getBrandingData();
  }

  ngAfterContentInit(): void {
    // this.contentLoaded = true;
  }

  public onClick(i: number, cat: string): void {
    $('.card.isClicked').removeClass('isClicked');

    let clickState = this.branding[cat][i]['clickState'];

    this.branding[cat][i]['clickState'] = !clickState;
  }

  public getData(): void {
    this.getBrandingData();
  }

  private getBrandingData(): void {
    if(!this.contentLoaded) {
      this.api.getData('assets/data/branding.data.json').subscribe(data => {
        this.branding = data.body;
        this.contentLoaded = true;
      });
    }
  }
}
