import { Component, OnInit, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations'
import { ApiService } from '../services/api.service';

declare var $;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeInOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class HomeComponent implements OnInit, AfterContentInit {

  swapProfile: boolean = false;
  clients: any;
  things: any;
  thingsContentLoaded: boolean = false;
  clientContentLoaded: boolean = false;
  backgroundImagePath: string = '../assets/global/home-image';

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    // this.getClientData();
    // this.getThingsData();

    //main-content
    let bgElemId =  document.getElementById('main-content');
    bgElemId.style.backgroundImage = "url(" + this.backgroundImagePath + (Math.floor(Math.random() * 3) + 1 ) + '.jpg' + ")";
    bgElemId.style.backgroundPosition = 'center center';
    bgElemId.style.backgroundRepeat =  'no-repeat';
    bgElemId.style.backgroundAttachment = 'fixed';
  }

  ngAfterContentInit(): void {
    $('.page-bg').css({
      'background-color': '#363636',
      'background-image': 'url(../../../assets/global/footerbg.png)',
      'background-repeat': 'repeat'
    });
  }

  public onSwapProfile(): void {
    this.swapProfile = !this.swapProfile;
  }

  public getClientData(): void {
    this.api.getData('assets/data/home-clients.data.json').subscribe(data => {
      this.clients = data.body.clients;
      this.clientContentLoaded = true;
    });
  }

  public getThingsData(): void {
    this.api.getData('assets/data/home-things.data.json').subscribe(data => {
      this.things = data.body.things;
      this.thingsContentLoaded = true;
    });
  }
}
